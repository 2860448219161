import React, { useEffect } from "react";

const CustomerReviews = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://static.elfsight.com/platform/platform.js";
    script.setAttribute("data-use-service-core", "defer");
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="max-w-3xl mx-auto p-4 bg-white rounded-lg shadow-md">
      <h2 className="text-2xl font-bold text-center mb-4">Customer Reviews</h2>
      <div
        className="elfsight-app-143b9197-f8a7-4276-af22-39d221a3d570"
        data-elfsight-app-lazy
      ></div>
    </div>
  );
};

export default CustomerReviews;
